const language = {
    aboutIcecraftTitle: "About the project",
    aboutIcecraft: "IceCraft was created in 2019 as a small recreational project. My goal is to bring together a small community. The server is partly RPG-like and will be developed further. I try to respond to player requests as best as possible, if the genre allows it.",
    project: "IceCraft is a small project that I have been working on for a while. With the goal to bring a small community together.\nThe server is not vanilla at all. If you want to learn more, follow the link.",
    projectLink: "read more...",
    projectTitle: "IceCraft",
    fishingText: "Vanilla fishing has been reworked with a specially developed system. You can fish for over 30 different items and fishes, which you can view at any time in a lexicon. <br></br>The command to open your lexicon is /lexicon",
    fishingHeader: "Fishing",
    economyText: "The economy acts dynamically so that an item never has the same price. <br></br>The prices of the items change in real time. That is, as soon as you sell an item, it gets a new price. <br></br>If you are creative, you can also write books. These can be sold at the librarian. This makes your book available to all other players and you earn money from it.",
    economyHeader: "Economy",
    horseText: "For horse lovers here is also something:. A pet system. <br></br>Horses that are tamed have different breeds, including Friesian, Andalusian, Haflinger and many more. <br></br>However, a horse must be fed daily and it requires care. <br></br>If it is neglected, it dies. However, it can also be revived.",
    horseHeader: "Pets"
}

export default language;