import React from "react";
import TextBox from "./core/TextBox";
import Text from "./core/Text";
import { Helmet } from "react-helmet-async";
import { HelmetProvider } from "react-helmet-async";

import useTranslation from "./customHooks/translations";

function TheProject() {
  const translation = useTranslation();
  return (
    <div className="home">
      <HelmetProvider>
        <Helmet>
          <meta name="description" content="Unser Regelwerk, wie überall gibt es auch hier entsprechende Regeln, um ein friedliches miteinander zu schaffen. Bitte lies Sie durch halte dich an Sie." />
          <title>Das Regelwerk von IceCraft für ein friedliches miteinander</title>
        </Helmet>
      </HelmetProvider>
      <div className="icecraft-swifty">
        <div className="col-lg-12">
          <h1 className="font-weight-light"><p className="text-center">{translation.rulesTitle}</p></h1>
        </div>
        <div className="col-lg-12">
          <Text text={translation.rulesText} />
        </div>
      </div>
      <div className="icecraft-info-container">
        <TextBox header={translation.rulesCrossNetwork} text={translation.rulesCrossNetworkText} />
        <TextBox header={translation.rulesDiscord} text={translation.rulesDiscordText} />
        <TextBox header={translation.rulesMinecraft} text={translation.rulesMinecraftText} />
        <TextBox header={translation.rulesModification} text={translation.rulesModificationText} />
      </div>
    </div>
  );
}

export default TheProject;