import React from "react";

function CookiePolicy() {
    return (
        <div className="container">
            <div className="my-5">
                <h1>Cookie Policy</h1>
                <h2><strong>Was sind Cookies?</strong></h2>
                <p>
                    Cookies sind Textdateien, die temporär im Browser eines Website-Nutzers deponiert werden. Häufig werden sie eingesetzt, um den Nutzern mehr Komfort beim Surfen zu bieten und um sicherzustellen, dass bestimmte Website-Anwendungen reibungslos funktionieren, wie z.B. der Warenkorb bei Onlineshops. Andere Cookies tracken z.B. das Einkaufverhalten des Websitenutzers und können dadurch personalisierte Werbung anzeigen.
                </p>
                <h3><strong>Notwendige Cookies</strong></h3>
                <p>
                    Diese Cookies sind notwendig, um Inhalte der Website zu nutzen. Ohne diese Cookies, würde unser Cookie-Consent nicht funktionieren.
                </p>
                <h3><strong>Drittanbieter Cookies</strong></h3>
                <p>
                    Diese Website bettet Medien von Discord ein. Drittanbieter Cookies werden dazu verwendet, um diese Medien anzuzeigen. Wenn du Drittanbieter Cookies zustimmst, dann werden Cookies von diesem Anbieter auf deinem Computer gespeichert.
                </p>
                <p>
                    Weitere Informationen zu den Datenschutzbestimmung von Discord können unter <a href="https://discord.com/privacy" rel="noreferrer" target="_blank">https://discord.com/privacy</a> eingelesen werden.
                </p>
                <h3><strong>Statistics</strong></h3>
                <p>
                    Diese Website trackt nicht das Verhalten von Nutzern.
                </p>
                <h4><strong>Marketing</strong></h4>
                <p>
                    Diese Website nutzt keine Cookies für Werbe-Zwecken.
                </p>
            </div>
        </div>
    );
}

export default CookiePolicy;