import React from 'react';

const Picture = ({path, alt}) => {
    return (
        <div>
            <img
                className="img-fluid rounded mb-4 mb-lg-0 d-block m-auto"
                src={path}
                alt={alt}
              />         
        </div>
    )
}

export default Picture;