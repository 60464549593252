import { CookieBanner } from "@palmabit/react-cookie-law";
import { useDispatch } from "./GlobalStateProvider";

const Cookie = () => {
    const dispatch = useDispatch();
    function acceptcookies(cookie) {
        dispatch({ type: cookie });
    }
    //console.log(state.ThirdParty)
    return (
        <CookieBanner
            message="Diese Webseite verwendet Cookies, um Ihre Nutzererfahrung zu verbessern. Mehr Information finden Sie in unserer Cookie Policy."
            wholeDomain={true}
            //Komischer Hack, damit der State erst gesetzt wird, nachdem der CookieBanner fertig geladen hat.
            onAcceptPreferences={() => { setTimeout(acceptcookies, 5, "ACCEPT_COOKIES_PREFERENCES") }}
            showStatisticsOption={false}
            showMarketingOption={false}
            necessaryOptionText="Notwendig"
            preferencesOptionText="Drittanbieter Cookies"
            //statisticsOptionText="Statistik"
            //marketingOptionText="Marketing"
            acceptButtonText="Alle akzeptieren"
            savePreferencesButtonText="Ausgewählte speichern"
            declineButtonText="Ablehnen"
            managePreferencesButtonText="Einstellungen"
            privacyPolicyLinkText="Cookie Policy"
            policyLink="/cookiepolicy"
            preferencesDefaultChecked={true}
            statisticsDefaultChecked={false}
            marketingDefaultChecked={false}
            styles={{
                dialog: {
                    bottom: "0px",
                    left: "0px",
                    right: "0px",
                    zIndex: "100000",
                    position: "fixed",
                    background: "rgb(248, 247, 247)",
                    padding: "10px"
                },
                button: {
                    display: "inline-block",
                    background: "rgb(0, 0, 0)",
                    padding: "3px",
                    minWidth: "80px",
                    color: "rgb(255, 255, 255)",
                    textDecoration: "none",
                    fontSize: "14px",
                    fontWeight: "400",
                    marginRight: "5px",
                    marginLeft: "5px",
                    textAlign: "center",
                    whiteSpace: "nowrap",
                    cursor: "pointer",
                    borderRadius: "5px",
                    border: "none"
                }
            }}
        />
    );
}
export default Cookie;