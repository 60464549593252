import React from 'react';
import TextParser from './TextParser';

const TextBox = ({text, header}) => {
    return (
        <div className="col-sm">
            <h2 className="text-center">{header}</h2>
            <TextParser text={text}/>
        </div>
    )
}

export default TextBox;