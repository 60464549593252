import React from "react";
import { NavLink } from "react-router-dom";
import useTranslation from "./customHooks/translations";
import Text from "./core/Text";
import Picture from "./core/Picture";
import { Helmet } from "react-helmet-async";
import { HelmetProvider } from "react-helmet-async";
import GetCookies from "./customHooks/Cookie";

function Home() {
  const translation = useTranslation();
  return (
    <div className="container">
      <HelmetProvider>
        <Helmet>
          <meta name="description" content="Willkommen auf IceCraft den Survival Server mit RPG Elementen und eigenentwickelten Plugins." />
          <title>IceCraft - Dein Minecraft Survival Server mit RPG Elementen</title>
        </Helmet>
      </HelmetProvider>
      <div className="row align-items-center my-5">
        <div className="col-lg-7">
          <img
            className="img-fluid rounded mb-4 mb-lg-0"
            src="images/dynmap.jpg"
            alt=""
          />
        </div>
        <div className="col-lg-5">
          <h1 className="font-weight-light">{translation.projectTitle}</h1>
          <Text text={translation.project} /><NavLink to="/ueber-das-projekt">{translation.projectLink}</NavLink>
        </div>
      </div>
      <div className="row my-5">
        <div className="col-lg-5">
          <GetCookies ThirdPartyIFrame="discord" />
          <div id="margin-negative">
            <Text text={translation.discordText} /><a href="https://discord.gg/3wTkMZh" rel="noreferrer" target="_blank">Discord</a>
          </div>
        </div>
        <div className="col-lg-7">
          <a href="https://map.icecapa.de" rel="noreferrer" target="_blank"><Picture path="/images/dynmap_interface.jpg" /></a>
          <Text text={translation.dynmapText} /><a href="https://map.icecapa.de" rel="noreferrer" target="_blank">Karte</a>
        </div>
      </div>
    </div >
  );
}

export default Home;