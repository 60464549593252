//import React from 'react';
//import ReactDOM from 'react-dom';
//import './index.css';
//import App from './App';
//import reportWebVitals from './reportWebVitals';
//
//ReactDOM.render(
//  <React.StrictMode>
//    <App />
//  </React.StrictMode>,
//  document.getElementById('root')
//);
//
//// If you want to start measuring performance in your app, pass a function
//// to log results (for example: reportWebVitals(console.log))
//// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
//
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
//import * as serviceWorker from "./serviceWorker";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import {
  Navigation,
  Footer,
  Home,
  About,
  Contact,
  Blog,
  Posts,
  Post,
  TheProject,
  Page404,
  HeaderImage,
  Rules,
  CookiePolicy,
  Datenschutz,
  Impressum,
} from "./components";
import LanguageContextProvider from "./components/contexts/LanguageContext";
import { Provider } from "./components/customHooks/GlobalStateProvider";
import Cookie from "./components/customHooks/ShowCookieBanner";

ReactDOM.render(
  <React.StrictMode>
    <Provider>
      <Cookie></Cookie>
      <LanguageContextProvider>
        <Router>
          <Navigation />
          <HeaderImage />
          <Routes>
            <Route path="*" element={<Page404 />} />
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/ueber-das-projekt" element={<TheProject />} />
            <Route path="/regelwerk" element={<Rules />} />
            <Route path="/impressum" element={<Impressum />} />
            <Route path="/cookiepolicy" element={<CookiePolicy />} />
            <Route path="/datenschutz" element={<Datenschutz />} />
            <Route path="/blog" element={<Blog />}>
              <Route path="" element={<Posts />} />
              <Route path=":postSlug" element={<Post />} />
            </Route>
          </Routes>
          <Footer />
        </Router>
      </LanguageContextProvider>
    </Provider>
  </React.StrictMode>,

  document.getElementById("root")
);

//serviceWorker.unregister();