import React from "react";
import { NavLink } from "react-router-dom";
//<footer className="py-5 bg-dark fixed-bottom">
function Footer() {
  return (
    <div className="footer">
      <footer className="py-4" id="navbar_footer">
        <p className="m-0 text-center text-white">
          Copyright &copy; IceCraft 2022
        </p>
        <nav className="navbar navbar-expand-md navbar-dark" id="navbar_footer">
          <ul className="navbar-nav ms-auto">
            <li className="nav-item hover-underline-animation">
              <NavLink className="nav-link" to="/impressum">
                Impressum
              </NavLink>
            </li>
            <li className="nav-item hover-underline-animation">
            <NavLink className="nav-link" to="/cookiepolicy">
              Cookie Policy
            </NavLink>
            </li>
            <li className="nav-item hover-underline-animation">
            <NavLink className="nav-link" to="/datenschutz">
              Datenschutz
            </NavLink>
            </li>
          </ul>
        </nav>
      </footer>
    </div>
  );
}

export default Footer;