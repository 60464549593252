import React from 'react';

const PictureBox = ({path, alt}) => {
    return (
        <div className="col-sm">
            <img
                className="img-fluid rounded mb-4 mb-lg-0 d-block m-auto"
                src={path}
                alt={alt}
              />         
        </div>
    )
}

export default PictureBox;