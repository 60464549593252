import React from "react";
import TextBox from "./core/TextBox";
import Text from "./core/Text";
import PictureBox from "./core/PictureBox";
import { Helmet } from "react-helmet-async";
import { HelmetProvider } from "react-helmet-async";
import useTranslation from "./customHooks/translations";

function TheProject() {
  const translation = useTranslation();
  return (
    <div className="home">
      <HelmetProvider>
        <Helmet>
          <meta name="description" content="IceCraft wurde im Jahr 2019 als kleines Freizeit Projekt ins Leben gerufen. Das Ziel ist es eine kleine Community zusammenzubringen." />
          <title>Über unser Projekt und Features - IceCraft</title>
        </Helmet>
      </HelmetProvider>
      <div className="icecraft-swifty">
        <div className="col-lg-12">
          <h1 className="font-weight-light"><p className="text-center">{translation.aboutIcecraftTitle}</p></h1>
        </div>
        <div className="col-lg-12">
          <Text text={translation.aboutIcecraft} />
        </div>
      </div>
      <div className="icecraft-info-container">
        <div className="row">
          <PictureBox path="/images/lexicon.jpg" />
          <TextBox header={translation.fishingHeader} text={translation.fishingText} />
        </div></div>
      <div className="icecraft-info-container">
        <div className="row">
          <TextBox header={translation.economyHeader} text={translation.economyText} />
          <PictureBox path="/images/economy.gif" />
        </div></div>
      <div className="icecraft-info-container">
        <div className="row">
          <PictureBox path="/images/horse.jpg" />
          <TextBox header={translation.horseHeader} text={translation.horseText} />
        </div>
      </div>
      <div className="icecraft-info-container">
        <div className="row">
          <TextBox header={translation.homesHeader} text={translation.homesText} />
          <PictureBox path="/images/campfire_creation.gif" />
        </div>
      </div>
      <div className="icecraft-info-container">
        <div className="row">
          <PictureBox path="/images/death_teleport.gif" />
          <TextBox header={translation.deathHeader} text={translation.deathText} />
        </div>
      </div>
    </div>
  );
}

export default TheProject;