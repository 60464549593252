import React from 'react';

function parseNewLine(text) {
    var parsedText = text.split('\n').map((str, index) => {
        var parsed = <p key={index}>{parseBracket(str)}</p>
        return parsed
    })
    return parsedText
}

function parseBracket(text) {
    var parsedText = text.split('\b').map((str, index) => {
        var parsed = [str, <br key={index}></br>]
        return parsed
    })
    return parsedText
}
const TextParser = ({text}) => {
    var returnText = []
    returnText.push(parseNewLine(text))
    return returnText
}

export default TextParser;