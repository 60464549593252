const language = {
    aboutIcecraftTitle: "Über das Projekt",
    aboutIcecraft: "IceCraft wurde im Jahr 2019 als kleines Freizeit Projekt ins Leben gerufen. Mein Ziel ist es eine kleine Community zusammenzubringen. Der Server ist teils RPG mäßig angehaucht und wird immer weiter weiterentwickelt. Ich versuche bestmöglichst auf Spielerwünsche einzugehen, sofern es das Genre zulässt.",
    project: "IceCraft ist ein kleines Projekt, an welchem ich schon eine Zeit lang arbeite. Mit dem Ziel eine kleine Community zusammenzubringen.\nDer Server ist keineswegs Vanilla. Wenn du mehr Erfahren möchtest, folge dem Link.",
    projectLink: "weiterlesen...",
    projectTitle: "IceCraft",
    fishingText: "Das Angeln wurde mit einem eigens entwickeltem System überarbeitet. \nDu kannst jetzt über 30 verschiedene Items und Fische angeln, welche du jederzeit in deinem Lexikon anschauen kannst. \nDer Befehl um dein Lexikon zu öffnen lautet /lexicon",
    fishingHeader: "Angeln",
    economyText: "Die Wirtschaft agiert dynamisch, sodass ein Item niemals den gleichen Preis hat. \nDie Preise der Items ändert sich in Echtzeit. Das heißt, sobald du ein Gegenstand verkaufst erhält er einen neuen Preis. \nWenn du Kreativ bist, kannst du auch Bücher schreiben. Diese können bei der Bibliothekarin verkauft werden. Damit wird dein Buch für alle anderen Spieler zugänglich gemacht und du verdienst daran.",
    economyHeader: "Wirtschaft",
    horseText: "Für Pferdeliebhaber gibt es hier auch etwas. Nämlich Haustiere. \nPferde die gezähmt werden haben unterschiedliche Rassen, darunter Friesen, Andalusier, Haflinger und noch viele mehr. \nEin Pferd muss allerdings tägliche gefüttert werden und es bedarf Pflege. \n Wird es vernachlässigt, stirbt es. Kann jedoch wiederbelebt werden.",
    horseHeader: "Pferdesystem",
    homesHeader: "Homepunkte",
    homesText: "Bei uns funktionieren Homepunkte ein wenig anders. \nUm ein Homepunkt zu erstellen benötigst du einen Hexenknochen sowie ein Lagerfeuer. \nDie Anzahl der möglichen Homepunkte ist nicht begrenzt.",
    deathHeader: "Todespunkte - /back",
    deathText: "Ähnlich wie Homes wird ein spezielles Item benötigt, um zum Todespunkt zurückzugelangen. \nDieses ist wie der Hexenknochen herstellbar. Weitere Infos findest du in unserem Wiki.",
    navStart: "Startseite",
    navUeberUns: "Über uns",
    navKarte: "Karte",
    navHeaderText: "IceCraft - Dein Survival Server",
    navRules: "Regelwerk",
    navWiki: "Wiki",
    dynmapText: "Klicke auf das Bild oder die Karte um zur Dynmap zu gelangen!",
    discordText: "Besuche uns doch mal auf unserem Discord!",
    rulesTitle: "Regelwerk",
    rulesText: "Wie überall gibt's hier auch entsprechende Regeln, um ein friedliches miteinander zu schaffen. Bitte lies Sie durch und stelle sicher, dass Du dich an Sie hälst. Bei Missachtung können Strafen erfolgen.",
    rulesCrossNetwork: "Platformübergreifende Regeln",
    rulesCrossNetworkText: "(1) Beleidigungen, Drohungen, Provokation gegen andere Nutzer sind verboten. Bitte behandele jeden respektvoll. Denk daran, dass am anderen Ende auch eine andere Person sitzt. Wenn dir eine Person sagt du sollst aufhören, dann höre auf!\n(2) Rassistische, sexistische, ethisch inakzeptable und pornografische Inhalte sind verboten. Wenn du dir bei einem Post nicht sicher bist, poste ihn nicht.\n(3) Flute Textkanäle nicht mit Spam. Auf Spam kann eine Verwarnung oder Mute folgen.",
    rulesDiscord: "Discord",
    rulesDiscordText: "(1) Pinge niemanden, außer der Ping ist an diese Person gerichtet. Wenn du Fragen zum Server, Hilfe benötigst oder einen Bug melden möchtest, nutze den dafür vorgesehenden Text-Kanal. Ein Teammitglied dafür zu Pingen ist kein Grund. \bFalls du jemand ausversehen gepingt hast, lösche deine Nachricht nicht um Ghostpings zu vermeiden. Entschuldige dich stattdessen.\n(2) Schreibe niemanden Privat an, außer du kennst diese Person bereits und diese Person hat es dir erlaubt. Wenn du einen Bug melden möchtest oder Vorschläge hast, schreibe sie in den dafür vorgesehenden Channel.",
    rulesMinecraft: "Minecraft",
    rulesMinecraftText: "(1) Automatische Farmen sind in der Overworld verboten. Dir steht es frei in der Farmwelt: automatische Farmen, XP Farmen, Mobgrinder und ähnliche Bauwerke zu bauen.\n(2) Das Ausnutzen von Bugs und Glitches ist strengstens untersagt.\n(3) Hacked-Clients und Modifikationen welche dir einen Vorteil verschaffen sind nicht gestattet. Wenn du dir unsicher bist, schaue in der unteren Liste der erlaubten Modifikationen vorbei. Sollte eine Modifikation nicht gelistet sein, frage bitte vorher nach.",
    rulesModification: "Modifikation",
    rulesModificationText: "Erlaubte Modifikationen: OptiFine, Sodium, Iris, NotEnoughAnimations, Eating Animation, Cosmetica, Bobby, cloth-basic-math, Visuality, Mod Menu, Inventory Tweaks\nVerbotene Modifikationen: FullBright"
}

export default language;