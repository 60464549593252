import '../TodoPage.css';
import React from "react";
import { NavLink } from "react-router-dom";
import LanguageHandler from "./LanguageHandler";

import useTranslation from "./customHooks/translations";

function Navigation() {
  const translation = useTranslation();
  return (
    <div className="navigation sticky-top">
      <nav className="navbar navbar-expand-md navbar-dark" id="navfoot">
        <div className="container" id="navfoot">
          <NavLink className="navbar-brand" to="/">
            {translation.navHeaderText}
          </NavLink>
          <div id="language" className="d-flex flex-row-reverse">
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" >
              <span className="navbar-toggler-icon"></span>
            </button>
          </div>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="ms-auto"></ul>
            <LanguageHandler />
            <ul className="navbar-nav ">
              <li className="nav-item hover-underline-animation">
                <NavLink className="nav-link" to="/">
                  {translation.navStart}
                </NavLink>
              </li>
              <li className="nav-item hover-underline-animation">
                <NavLink className="nav-link" to="/ueber-das-projekt">
                  {translation.navUeberUns}
                </NavLink>
              </li>
              <li className="nav-item hover-underline-animation">
                <NavLink className="nav-link" to="/regelwerk">
                  {translation.navRules}
                </NavLink>
              </li>
              <li className="nav-item hover-underline-animation">
                <a className="nav-link" href="https://discord.gg/3wTkMZh" rel="noreferrer" target="_blank">Discord</a>
              </li>
              <li className="nav-item hover-underline-animation">
                <a className="nav-link" href="https://map.icecapa.de/" rel="noreferrer" target="_blank">{translation.navKarte}</a>
              </li>
              <li className="nav-item hover-underline-animation">
                <a className="nav-link" href="https://wiki.icecapa.de/" rel="noreferrer" target="_blank">{translation.navWiki}</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Navigation;