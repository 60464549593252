import React from "react";

function Page404() {
  return (
      <div className="container">
        <div className="row align-items-center my-5">
          <div className="col-lg-5">
            <h1 className="font-weight-light">Page 404</h1>
            <p>
              Diese Seite existiert nicht! Hast du dich verirrt?
            </p>
          </div>
        </div>
      </div>
  );
}

export default Page404;