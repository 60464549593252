import React from 'react';

const HeaderImage = ({ text }) => {
  return (
    <div className="header">
      <div id="intro" className="p-5 text-center bg-image">
      </div>
    </div>
  )
}

export default HeaderImage;