const language = {
    aboutIcecraftTitle: "Über's Projekt",
    aboutIcecraft: "IceCraft is im Johr 2019 ois a gloans Freizeitprojekt ins Lem gruafa woan. Mein Ziel is a gloane Community zamzumbringa. Da Server is a weng RPG-mäßig oghaucht und werd oiwei weida entwickelt. Ic vasuach bestmöglichst auf Spielerwünsche eizumgeh, solangs as Genre zualasst.",
    project: "IceCraft is a gloans Projekt, an dem i scho a Zeit lang abad. Mei Ziel isFinite, a gloane Community zamzumbringa.\nDer Server is überhapt ned Vanilla. Wennst mehra erfahrn wuist, foig dem Link.",
    projectLink: "Weidalesn...",
    projectTitle: "IceCraft",
    fishingText: "S' Vanillaangeln is mid am eigna System überarbad woan.. Du kosd mehr wia 30 verschiedne Fisch und Items angln, de wo du dann oiwei in deim Lexikon nachschaun kosd.\nDa Befehl zum aufmacha vom Lexikon is /lexicon",
    fishingHeader: "Angln",
    economyText: "D' Wirtschaft argiert dynamisch, sodass a Item niamois den selben Preis hod. \nD' Preise vo de Items ändert si in Echtzeit. Des hoaßd, soboid du an Gegenstand vakaffst griagt er an neue Preis. \nWennst Kreativ bist, kosd a Biacha schreim. De kosd bei da Bibliothekarin verkaffa. Des hoasd, damid werd dei Buach für alle andan Spielen a sichtbar und du kosd damit vadina.",
    economyHeader: "Wirtschaft",
    horseText: "Für Pferdlliebhaber gibts do a was: A Haustiersystem. \nPferdl die zähmt wern ham vaschiedne Rassen, unter andam Friesen, Andalusier, Haflinger und no vui mehra. \nA Pferdl muas allerdings jedn Dog gfuadad wern und es braucht Pflege. Werds vernachlässigt, vareckts. Ko aba a wiederbelebt wern.",
    horseHeader: "Pferdlsystem"
}

export default language;