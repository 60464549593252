import { useReducer } from 'react';
import { createContainer } from 'react-tracked';

const initialState = {
    count: 0,
    ThirdParty: "false"
};
const reducer = (state, action) => {
    switch (action.type) {
        case 'ACCEPT_COOKIES_PREFERENCES':
            return { ...state, ThirdParty: "true" };
        case 'DENIED_COOKIES_PREFERENCES':
            return { ...state, ThirdParty: "false" };
        default:
            return state;
    }
};
const useMyState = () => useReducer(reducer, initialState);

export const {
    Provider,
    useTrackedState,
    useUpdate: useDispatch,
} = createContainer(useMyState);