import { useTrackedState } from "./GlobalStateProvider";

const GetCookies = ({ ThirdPartyIFrame }) => {
    const state = useTrackedState();
    if (state.ThirdParty === "true") {
        switch (ThirdPartyIFrame) {
            case "discord":
                return <iframe title="discord" src="https://discord.com/widget?id=759820507012268113&theme=dark" width="350" height="480" allowtransparency="true" frameBorder="0" sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"></iframe>;
            default:
                return <p>Bitte stimme den Drittanbieter Cookies zu, um dieses Element anzuzeigen.</p>;
        }
    } else {
        return <p>Bitte stimme den Drittanbieter Cookies zu, um dieses Element anzuzeigen.</p>;
    }
};

export default GetCookies;